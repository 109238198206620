@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .paused {
    animation-play-state: paused;
  }
}

body {
  background-color: #000F24;
}


.newsSwiper .swiper-button-next,
.newsSwiper .swiper-button-prev {
  background-size: contain;
  transform: scale(0.8);
  background-color: rgba(20, 17, 48, 0.3);
  padding: 32px;
  border-radius: 50%;
  color: white;
  user-select: none;
}

.newsSwiper .swiper-button-next:hover,
.newsSwiper .swiper-button-prev:hover {
  transition: 0.5s;
  background-color: #50b3c1;
}

@media screen and (max-width: 1240px) {
  .newsSwiper .swiper-button-next,
  .newsSwiper .swiper-button-prev {
    transform: scale(0.6);
  }
  .newsSwiper .swiper-button-next:hover,
  .newsSwiper .swiper-button-prev:hover {
    background-color: rgba(20, 17, 48, 0.3);
  }
}

@media screen and (max-width: 540px) {
  .newsSwiper .swiper-button-next,
  .newsSwiper .swiper-button-prev {
    visibility: hidden;
  }
}

@media screen and (max-width: 480px) {
  .paragraph1 {
    display: hidden;
  }
}

/* Loader animation start */
.container_of_loader {
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid;
  border-color: #4bb2bf transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Loader animation END */

/* Modal open style opacity START */
.modal {
  opacity: 0;
  transition: 0.5s;
  pointer-events: none;
}
.modal.active {
  opacity: 1;
  pointer-events: all;
}

.modal__content {
  transition: 0.4s all;
}
/* .modal__content.active {
 
} */
/* Modal open style opacity END */

.pagination {
  margin-top: 35px !important;
}

#tsparticles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.pagination .active > .page-link {
  background-color: var(
    --pagination-active-bg,
    var(--pagination-color, #596296)
  ) !important;
  border-color: var(
    --pagination-active-border-color,
    var(--pagination-active-bg, var(--pagination-color, #596296))
  ) !important;
}

.search_list_modal {
  z-index: 9;
  box-shadow: 0 1px 2px rgba(193, 192, 224, 0.5);
  border-top: none;
}

p.xs.mt-\[15px\].break-words.whitespace-normal ul {
  list-style: initial; /* Восстанавливает стандартный стиль списка */
  margin-top: 10px; /* Убирает переопределение margin */
  padding-left: 25px; /* Убирает переопределение padding */
}
.search_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.loader_search {
  width: 48px;
  height: 48px;
  border: 5px dotted #5e2d7a;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.search_list_style {
  text-align: center;
  padding-top: 25px;
}
selector::-webkit-search-cancel-button {
  cursor: pointer;
}


/* most selling product */
.mySwiper .swiper-button-prev,
.mySwiper .swiper-button-next {
  background-size: contain;
  background-color: rgba(20, 17, 48, 0.3);
  transform: scale(0.7);
  color: white;
  padding: 32px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  user-select: none;
}

.mySwiper .swiper-button-prev:hover,
.mySwiper .swiper-button-next:hover {
  background: #50b3c1;
  transition: all 0.25s ease-in-out;
}

